import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'
// initialize an empty api service that we'll inject endpoints into later as needed

const nodeApi = createApi({
  reducerPath: 'nodeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NODE_REACT_APP_API_URL || '/node-api',
    prepareHeaders: (headers) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = window.localStorage.getItem('aiot_t');
      const methodiaAiotCookie = Cookies.get('aiot_methodia_user');
      console.log('methodiaAiotCookie', methodiaAiotCookie);

      if (token) {
        headers.set('Authorization', `Token ${token}`);
      }
      if (methodiaAiotCookie) {
        headers.set('x-aiot-methodia-user', methodiaAiotCookie);
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getReportAsPDF: builder.mutation<void, any>({
      query: (body) => ({
        url: `/report`,
        method: 'POST',
        body,
        responseHandler: async (response) => {
          if (response.ok && response.headers.get('Content-Type') === 'application/pdf') {
            const blob = await response.blob()
            const pdfUrl = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = pdfUrl
            a.download = `${body.report_name || 'report'}.pdf`
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            URL.revokeObjectURL(pdfUrl)
          }

          return null
        },
      }),
    }),
    getBillingReport: builder.mutation<void, any>({
      query: (body) => ({
        url: `/billing-report`,
        method: 'POST',
        body,
        responseHandler: async (response) => {
          if (response.ok && response.headers.get('Content-Type') === 'application/pdf') {
            const blob = await response.blob()
            const pdfUrl = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = pdfUrl
            a.download = `${body.report_name || 'report'}.pdf`
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            URL.revokeObjectURL(pdfUrl)
          }

          return null
        },
      }),
    }),
  }),
})
export default nodeApi
