import { Dispatch } from '@reduxjs/toolkit'
import nodeApi from './node'
import pythonApi from './python'

// initialize an empty api service that we'll inject endpoints into later as needed

export { pythonApi, nodeApi }
export const { useGetReportAsPDFMutation, useGetBillingReportMutation } = nodeApi
export const { useGetDeviceDataQuery } = pythonApi

export const resetAPISState = (dispatch: Dispatch<any>) => {
  dispatch(nodeApi.util.resetApiState())
  dispatch(pythonApi.util.resetApiState())
}
