import { KILOWATT_HOUR, KILOWATT, ENERGY_PLANT_LOGGER, ENERGY_GENERATOR } from './consts'

export const mutatePriceUnit = (priceUnit: string) => {
  if (priceUnit === KILOWATT) {
    return KILOWATT_HOUR
  }
}

export const splitAndUppercase = (string: string, delimeter: string) =>
  string
    .split(delimeter)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')

export const addCommasToNumber = (num: number | string) => {
  if (typeof num === 'string') {
    return num
  }

  if (!num) {
    return Number(0).toLocaleString()
  }

  return parseFloat(num.toFixed(2)).toLocaleString()
}

export const getLocationIndex = (data: any, locationId: string | null) => {
  return data.findIndex((item: any) => item.id.toString() === locationId) + 1
}

export const calculatePercentage = (part: number, whole: number, roundingIndex: number=0): number =>{
  if(whole === 0 || part === 0){
    return 0
  }
  const result = Number(((part / whole) * 100).toFixed(roundingIndex))
  
  return result
}

const priceUnitMap = {
  kW: KILOWATT_HOUR,
  MW: 'mWh',
}

export const getPriceUnitMap = (priceUnit?: keyof typeof priceUnitMap) =>
  priceUnitMap[priceUnit || KILOWATT]

const DEVICE_STATUS = {
  null: 'N/A',
  undefined: 'N/A',
  0: 'Idle',
  1: 'On-Grid',
  2: 'On-Grid: self derating',
  3: 'On-Grid: Power limit',
  4: 'Planned outage',
  5: 'Power limit outage',
  6: 'Fault outage',
  7: 'Communication interrupt',
}

export type DeviceStatusKeys = keyof typeof DEVICE_STATUS

export const getDeviceStatus = (status: DeviceStatusKeys): string => DEVICE_STATUS[status]

export const getDeviceCurrentDataFieldsByCategory = (category) => {
  switch (category) {
    case ENERGY_PLANT_LOGGER:
      return ['generated_power', 'current', 'input_power', 'dc_current']
    case ENERGY_GENERATOR:
      return []
    default:
      return ['consumption']
  }
}

export const getSubdomain = () => window.location.hostname.split('.')[0]

export const METHODIA_SUBDOMAIN = 'methodia'

export const downloadFile = (href, filename) => {
  const link = document.createElement('a')
  link.href = href
  link.download = filename
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
