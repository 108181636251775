import { useMemo } from 'react'
import { useGetMeasurementTypesQuery } from '../../locations/api/locationsApi'
import MultiSelect from '../components/MultiSelect'
import { NumberInput, NumberInputField } from '@chakra-ui/react'

export const EnergyConsumptionReportFilters = ({
    selectedMeasurementTypes,
    setSelectedMeasurementTypes,
    selectedDeviceTypes,
    register,
    setValue,
}) => {
    const { data: measurementTypes } = useGetMeasurementTypesQuery({
        deviceTypeIds: selectedDeviceTypes,
    })

    const measurementTypesSelect = useMemo(
        () =>
            measurementTypes?.results.map(({ id, name }) => ({
                id,
                name,
            })),
        [measurementTypes],
    )

    return (
        <>
            <MultiSelect
                items={measurementTypesSelect}
                setSelectedOptions={setSelectedMeasurementTypes}
                selectedOptions={selectedMeasurementTypes}
                setValue={setValue}
                invalidateFields={['selectedDevices']}
                header={`Measurement type/s${selectedMeasurementTypes.length > 0
                    ? ` (${selectedMeasurementTypes.length})`
                    : ''
                    }`}
                tooltip={{
                    label: 'Please select period',
                    hasArrow: true,
                    placement: 'top',
                }}
            />
            <NumberInput>
                <NumberInputField
                    w="full"
                    bg="white"
                    border="1px"
                    height="48px"
                    borderColor='border.strong'
                    textAlign="left"
                    fontSize="18px"
                    fontWeight="normal"
                    id={'ibexPrice'}
                    {...register('ibexPrice')}
                    placeholder="Enter custom IBEX price (Optional)" />
            </NumberInput>
            <NumberInput>
                <NumberInputField
                    w="full"
                    bg="white"
                    border="1px"
                    height="48px"
                    borderColor='border.strong'
                    textAlign="left"
                    fontSize="18px"
                    fontWeight="normal"
                    id={'carbonIntensity'}
                    {...register('carbonIntensity')}
                    placeholder="Enter custom Carbon intensity (Optional)" />
            </NumberInput>
        </>
    )
}
